#root {
  /* background-image: url("../src/allPages/images/CRBTtunewebsite-01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'GoldmanBold', cursive; */
  height: 100vh;
}

body {
  background-image: url("../src/allPages/images/CRBTtunewebsite-01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* This will fix the background image */
  font-family: 'GoldmanBold', cursive;
}


.link {
  display: flex;
  color: white;
  padding: 10px 15px;
  gap: 15px;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  width: 205px;
  text-decoration: none;
}

.link_text {
  white-space: nowrape;
  font-size: 15px;
}

.link:hover {
  background: rgb(3, 51, 80);
  color: rgb(245, 241, 241);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  border-right: 4px solid white;
}

.active {
  background: rgb(2, 46, 68);
  background-color: transparent;
  border-right: 4px solid white;
}